export const aboutUsData = [
    {
        id:3,
        title:"",
        details:"Wilmar sports is an incitive to explore sports industry, through redefining,  customizing and exploring unleashed potential of research innovation in sports wears and its gears. To concern the peak performance of each talents across the globe.",
    },
    {
        id:4,
        title:"",
        details:"Identity the uniqueness in each sports men and customizing his/her training and put up tailor made gears and supplier to explode the peak performance would not be a unique leap , In an customer friendly application made compactable within your budget .",
    },
    {
        id:5,
        title:"",
        details:"We love to serve all aspiring sports and games warriors to accomplish your journey to success through providing world class mentorship and training arrangement platform ensure you success in your finger tips with ample amount  of sponsor to support you and your achieves comes true with a mutual corporation with the corporates and SMES across the globe to enjoy the journey companionship.",
    },
    {
        id:6,
        title:"",
        details:"Lorem ipsum dolor sit amet, consectetur adipiscing elit"+
        "Vivamus sed libero id ligula iaculis vehicula. Sed cursus, est"+
        "eu volutpat ornare, sem risus malesuada arcu.",
    },
    {
        id:1,
        title:"Vision",
        details:"Design and redefine Costumes, gears, training and support to tap extreme performance. And nurturing outstanding competency. through spill the vibe of sportsman spirit across the globe at any cost.",
    },
    {
        id:2,
        title:"Mission",
        details:"Combing competency to capture the crown by 2030"+
        "To Take Extreme and Adverse steps to accommodate and accomplish performance enhancement."+
        "Reach the unreached at any cost "+
        "Exploring experimental research to develop exponential growth. "
        ,
    },
]