import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import { useSpring, animated,config } from 'react-spring';
import { useInView } from 'react-intersection-observer';

import {aboutUsData} from "./AboutusData";
import Map from '../Map/Map';


const AboutUs = ({back}) => {

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const gifStyle={
    maxWidth:"300px",
    maxHeight:"400px",
    top:0,left:0,bottom:0,right:0,margin:'auto',
  }
  const fadeIn = useSpring({    
    opacity: 1,
      transform: inView ?'translateY(0px)' : 'trabslateY(-50px)',
      config : config.slow,
      from: { opacity: 0, transform: 'translateY(50px)' },
  });

  return (
    <div style={{...back,paddingTop: '100px',paddingBottom: '50px',}} ref={ref}>
      <Container maxWidth="lg" >
        <animated.div>
          <Typography variant="h4" align="center" gutterBottom>
            About Us
          </Typography>
        </animated.div>
        <Grid container spacing={3} >
          <Grid item xs={12} style={{}} >
            <img style={{...gifStyle}} src="./Gifs/wilmar.gif" alt="WILMAR SPORTS" />
          </Grid>
          {aboutUsData.map((item) => (
            <Grid item xs={12} md={12} key={item.id}>
              <animated.div style={fadeIn} ref={ref}>
                <Typography variant="h5" gutterBottom >
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color={"#ffff"}>
                    {item.details}
                  </Typography>
              </animated.div>
            </Grid>
          ))}
          <Map/>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutUs;
