import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid } from '@mui/material';

const JobApplicationForm = ({back}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone:'',
    address:'',
    coverLetter: '',
    resume: null,
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    
  };

  return (
   <div style={{...back,paddingTop:"50px",padding:"50px"}}>
     <Container maxWidth="sm">
      <Typography variant="h5" component="div" gutterBottom>
        Job Application Form
      </Typography>
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              fullWidth
              variant="outlined"
              required
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              fullWidth
              variant="outlined"
              type="email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mobile"
              fullWidth
              variant="outlined"
              type="number"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Address'
              aria-label="Cover Letter"
              fullWidth
              required
              value={formData.coverLetter}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              minRows={4}
              maxRows={6}
              aria-label="Cover Letter"
              placeholder="Cover Letter"
              fullWidth
              required
              value={formData.coverLetter}
              onChange={(e) => setFormData({ ...formData, coverLetter: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            Upload Resume <input
              type="file"
              accept=".pdf,.doc,.docx"
              required
              onChange={(e) => setFormData({ ...formData, resume: e.target.files[0] })}
            />
          </Grid>
         <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
         </Grid>
        </Grid>
      </form>
    </Container>
   </div>
  );
};

export default JobApplicationForm;
