import React,{useEffect, useState} from 'react'

import { Container, Grid, TextField } from '@mui/material'

import Account from '../Account/Account'
import axios from '../../axios'

function Profile() 
{
  const [user,setUser]=useState();

  let id=sessionStorage.getItem('user')

 
  useEffect(()=>{
    console.log(id)
    try
    {
        if(id!=null)
        {
          axios.get(`user/get/id/${id}`).then((response)=>{
            console.log(response)
            if(response.status===200)
            {
                setUser(response.data)
            }
          })
        }else{
          setUser(null)
        }
    }catch(error)
    {
        alert("something went wrong..")
        console.log(error)
    }
  },[id])

  return (
    
      user!=null?( 
      <div style={{height:"500px",backgroundColor:"#ffff"}}>
        <Container>
          hey I am {id}
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth 
                value={user.userName}
              />
            </Grid>
          </Grid>
        </Container>
      </div>)
      :(<Account/>)
  )
}

export default Profile
