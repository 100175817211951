export const gifData = [
    {
      id: 1,
      src: './Gifs/adball.gif',
      title: 'GIF 1',
    },
    {
      id: 2,
      src: './Gifs/adrun.gif',
      title: 'GIF 2',
    },
    {
      id: 3,
      src: './Gifs/adcloth.gif',
      title: 'GIF 3',
    },
  ];