import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';

import './Banner.css';

import {bannerImage} from '../../Styles/Styles';
import {bannerImages} from './BannerData'


const Banner = () => {

  let back=
  {
    backgroundColor:"#000000",
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
    }, 500); 

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div  style={{...back,}}>
      <Paper
        elevation={1}
        className="banner-container"
        style={{...back}}
      >
        <div className="image-wrapper"  style={{...back,}}>
          {bannerImages.map((image, index) => (
            <div
            style={{...back,}}
              key={index}
              className={`image-slide ${index === currentIndex ? 'active' : ''}`}
            >
              <img style={{...bannerImage}} src={image} alt={` ${index}`} />
            </div>
          ))}
        </div>
      </Paper>
    </div>
  );
};

export default Banner;
