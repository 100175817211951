import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Container, Typography, TextField, Button, Grid, Card, CardMedia } from '@mui/material';

import {contact,email,location} from '../../Datas/Contact'

const ContactUs = ({back}) => {
  
  const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    
    <div style={{...back}}>
    
          <div style={{ height:"90px"}}>
              <Typography variant="h4" align="center" gutterBottom pt={10}>
                  Contact Us
              </Typography>

          </div>
            <Container maxWidth='md'>

              <Grid container spacing={3} style={{marginTop:"30px",marginBottom:"50px"}}>
                <Grid item xs={12} md={6}>
                  <Card align='center' style={{padding:"20px",backgroundColor:"#1111"}}>
                    <CardMedia component={'img'}
                      src={contact.icon}
                      style={{maxWidth:"60px",top:0,left:0,right:0,bottom:0,margin:"auto",}}
                    />
                    <Typography variant="h6" style={{color:"#ffff"}}>
                        {contact.phone}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} >
                  <Card align='center' style={{padding:"20px",backgroundColor:"#1111"}}>
                    <CardMedia component={'img'}
                      src={email.icon}
                      style={{maxWidth:"60px",top:0,left:0,right:0,bottom:0,margin:"auto",}}
                    />
                    <Typography variant="h6" style={{color:"#ffff"}}>
                        {email.email}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} >
                  <Card align='center' style={{padding:"20px",backgroundColor:"#1111"}}>
                    <a href={location.link} target='_blank' rel="noreferrer" >
                    <CardMedia component={'img'}
                      src={location.icon}
                      style={{maxWidth:"60px",top:0,left:0,right:0,bottom:0,margin:"auto",}}
                    />
                    </a>
                    <Typography variant="h6" style={{color:"#ffff"}}>
                        {location.name}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} >
                  <Card align='center' style={{padding:"20px",backgroundColor:"#1111"}}>
                    <CardMedia component={'img'}
                      src={location.icon}
                      style={{maxWidth:"60px",top:0,left:0,right:0,bottom:0,margin:"auto",}}
                    />
                    <Typography variant="h6" style={{color:"#ffff"}}>
                        {location.name}
                    </Typography>
                  </Card>
                </Grid>
             </Grid>
            </Container>
          <animated.div style={{fadeIn}}>

            <Container maxWidth="md" 
            sx={{
              mt:10,
              backgroundColor:"#ffff",
              pt:6,
              pb:10,
            }}>
              <form  >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Name"
                      name='name'
                      color="warning"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name='mobileNumber'
                      label="Mobile Number"
                      fullWidth
                      variant="outlined"
                      color="warning"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      name='email'
                      color="warning"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Place"
                      name='place'
                      color="warning"
                      fullWidth
                      required
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      fullWidth
                      name='message'
                      variant="outlined"
                      color="warning"
                      multiline
                      rows={4}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="warning"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </animated.div>
          <div style={{height:"90px"}}>

          </div>
    
    </div>
  );
};

export default ContactUs;
