export const SocialMedia=
[
    {
        id:1,
        icon:"./icons/media/instagram.png",
        name:"Instagram",
        route:"https://www.instagram.com/wilmar_sports/"
    },
    {
        id:2,
        icon:"./icons/media/facebook.png",
        name:"Facebook",
        route:""
    },
    {
        id:3,
        icon:"./icons/media/twitter-sign.png",
        name:"Twitter",
        route:""
    },
    {
        id:4,
        icon:"./icons/media/linkedin-logo.png",
        name:"Thread",
        route:""
    },
    {
        id:5,
        icon:"./icons/media/whatsapp.png",
        name:"Whatsapp",
        route:""
    },
]