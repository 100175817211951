import React, { useEffect, useState,useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Container,Grid,Card,CardContent, Typography,Button, Paper,} from '@mui/material';

import axios from '../../axios';
import AnimatedDialog from '../pop-up/AnimatedDialog';


export let cartProduct=[] ;


const Products = ({back}) => {

  const navigate=useNavigate()

  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);

  let user=sessionStorage.getItem("user")

  const fetchData=useCallback(()=>{
    // Fetch product data from the backend
    try
    {
      axios.get('product/get/all').then((response) => {
        
        console.log(response)

        if(response.status===200)
        {
          setProducts(response.data);
        }else
        {
          alert("Something went wrong try again later")
        }
      });
    }catch(error)
    {
      alert("Cannot load products..")
      console.log(error)
    }
  },[])

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addToCart = (product) => 
  {
    if(user!=null)
    {
      setCart((prevCart) => [...prevCart, product]);
      cartProduct=product
      console.log(cart)
      return( <AnimatedDialog open={true} />)
//      navigate("cart")
    }else
    {
      navigate("../profile/login")
    }
  };

  return (
   <div style={{...back,paddingTop:"30px",paddingBottom:"50px"}}>
        <Paper style={{width:"100%",background:"#ffff",marginTop:"20px",padding:"10px"}}>
          <Grid container spacing={1}>
            <Grid item xs={4} >
              <Typography align='center'>
                Category
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography align='center'>
                price
              </Typography>
            </Grid>
            <Grid item xs={4} >
              <Typography align='center'>
                New
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      <Container maxWidth='xl' style={{paddingTop:"50px"}}>
       {
        products.length>0?(
          <Grid container spacing={3}>
          {products.map((product) => (
            <Grid item key={product.productID} xs={12} sm={6} md={4} lg={3}>
              <Card>
                {
                  product.images && product.images.length>0?(
                    <img
                     style={{height:"400px",width:"100%"}}
                      alt={product.images[0].name}
                      src={`data:image/jpeg;base64,${product.images[0].imagedata}`}
                    />
                  ):(<Grid>Image is loading..</Grid>)
                }
                <CardContent>
                  <Typography variant="h6">{product.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Category: {product.category}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Price: ₹{product.actualPrice}
                  </Typography>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => addToCart(product)}
                  >
                    Add to Cart
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        ):(<Grid>
            <Card>
              <CardContent>
                Product is Loading..
              </CardContent>
            </Card>
        </Grid>)
       }
      </Container>
   </div>
  );
};

export default Products;

