import React from 'react'

import { Container } from '@mui/material';

import Dashboard from '../Dashboard/Dashboard'
import AddProducts from '../Products/AddProducts';

import {category} from '../../Styles/Styles'
import EditProducts from '../Products/EditProducts';
import AddCategory from '../category/AddCategory';

function AdminHome() {
  return (
    <div style={{...category,paddingTop:"50px",}}>
        <Container maxWidth={'xl'}>
            <Dashboard/>
            <AddProducts/>
            <EditProducts/>
            <AddCategory/>
        </Container>
    </div>
  )
}

export default AdminHome;