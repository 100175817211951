import { createTheme } from '@mui/material/styles';

export  const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#D32F2F',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  // Other theme customizations...
});
