export const quotes = [
    {
        id:1,
        quote: '"The process is more important than the results. And if you take care of the process, you will get the results."',
        author: '  MS Dhoni'
    },
    {
        id:2,
        quote: '"Man needs his difficulties because they are necessary to enjoy success"',
        author: ' APJ Apdul Kalam'
      },
    {
        id:3,
        quote: '"You have to fight to reach your dream. You have to sacrifice and work hard for it. There is nothing more satisfying than seeing a happy and smiling child."',
        author: ' Leonel Messi'
      },
    {
        id:4,
        quote: '"I see myself as the best footballer in the world"',
        author: ' Cristiano Ronaldo'
    },
    {
        id:5,
        quote: '"I fear not the man who has practiced 10,000 kicks once, but I fear the man who has practiced one kick 10,000 times"',
        author: ' Bruce Lee'
    },
    {
        id:6,
        quote: '"Service to others is the rent you pay for your room here on earth."',
        author: ' Muhammad Ali'
      },
  ];