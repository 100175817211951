import RamenDiningRoundedIcon from '@mui/icons-material/RamenDiningRounded';
import FitnessCenterRoundedIcon from '@mui/icons-material/FitnessCenterRounded';

export const services = [
    {
      id: 1,
      title: 'Diet Instructions',
      imageUrl: '/icons/service/plan.png', // Replace with your image URL
      icon:<RamenDiningRoundedIcon color='warning' fontSize='large'/>,
      link:"service",
    },
    {
      id: 2,
      title: 'Sponsorship',
      imageUrl: '/icons/service/sponsorship.png', // Replace with your image URL
      icon:<RamenDiningRoundedIcon color='warning' fontSize='large'/>,

      link:"service",
    },
    
    {
      id: 3,
      title: 'Graphic Design',
      imageUrl: '/icons/service/designer.png', // Replace with your image URL
      icon:<RamenDiningRoundedIcon color='warning' fontSize='large'/>,
      link:"service",
    },
    
    {
      id: 4,
      title: 'Gym trainer',
      imageUrl: '/icons/service/gym.png', // Replace with your image URL
      icon:<FitnessCenterRoundedIcon color='warning' fontSize='large'/>,
      link:"service",
    },
    
    
    {
      id: 5,
      title: 'Coaching',
      imageUrl: '/icons/service/Coaching.png', // Replace with your image URL
      icon:<RamenDiningRoundedIcon color='warning' fontSize='large'/>,
      link:"service",

    },
    {
      id: 6,
      title: 'Sports News',
      imageUrl: '/icons/service/news.png', // Replace with your image URL
      icon:<RamenDiningRoundedIcon color='warning' fontSize='large'/>,
      link:"service",

    },
  ];