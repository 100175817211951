import React from 'react'

function Space() {
  return (
    <div style={{padding:"26px",
                maxHeight:"100px",background:"transparent",
                width:"100%",
                }}>
      
    </div>
  )
}

export default Space;

