import HomeIcon from '@mui/icons-material/Home';
import CategorySharpIcon from '@mui/icons-material/CategorySharp';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import LanRoundedIcon from '@mui/icons-material/LanRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";

import { Link } from 'react-router-dom';

import {defaultBack} from "../../../Styles/Styles";


export const mainNavbarItems = [
    {
        id : 1,
        label:"Home",
        icon:<HomeIcon style={{color:'#f15723'}} />,
        route:"home"
    },
    {
        id : 2,
        label:"Products",
        icon:<CategorySharpIcon style={{color:'#f15723'}}/>,
        route:"product"
    },
    {
        id : 8,
        label:"Category",
        icon:<CategorySharpIcon style={{color:'#f15723'}}/>,
        route:"category"
    },
    {
        id : 3,
        label:"About us",
        icon:<ApartmentRoundedIcon style={{color:'#f15723'}}/>,
        route:"about"
    },
    {
        id : 4,
        icon:<LanRoundedIcon style={{color:"#f15723"}}/>,
        label:"services",
        route:"services"
    },
    {
        id : 5,
        icon:<Diversity2RoundedIcon style={{color:"#f15723"}}/>,
        label:"Careers",
        route:"career"
    },
    {
        id : 6,
        icon:<SupportAgentRoundedIcon  style={{color:'#f15723'}}/>,
        label:"Contact us",
        route:"contact"
    },
    {
        id : 7,
        icon:<SupportAgentRoundedIcon  style={{color:'#f15723'}}/>,
        label:"Display",
        route:"display"
    }
];

export const settings = [
    {
        id : 1,
        icon:<PersonRoundedIcon style={{color:"#f15723"}}/>,
        label:"Profile",
        route:"profile"
    },
    {
        id : 2,
        icon:<ShoppingCartIcon style={{color:"#f15723"}}/>,
        label:"Cart",
        route:"cart"
    },
    {
        id : 3,
        icon:<Diversity2RoundedIcon style={{color:"#f15723"}}/>,
        label:"Careers",
        route:"career"
    },
    {
        id : 4,
        icon:<LogoutIcon style={{color:"#f15723"}}/>,
        label:"Logout",
        route:"logout"
    }
];

export const drawerLinks = (
    <div style={{...defaultBack,}}>
        <List >
            {mainNavbarItems.map((mainNavbarItems) => (
                <ListItem
                    
                    button
                    key={mainNavbarItems.label}
                    component={Link}
                    to={mainNavbarItems.route}
                    
                >
                    <ListItemIcon>{mainNavbarItems.icon}</ListItemIcon>
                    <ListItemText primary={mainNavbarItems.label} />
                </ListItem>
            ))}
      </List>
    </div>
  );

  export const userDrawerLinks = (
    <div style={{...defaultBack}}>
        <List 
            size="lg"
            component="nav"
            sx={{
              flex: 'none',
              fontSize: 'xl',
              '& > div': { justifyContent: 'center' },
            }}
        >
            {settings.map((settings) => (
                <ListItem
                    style={{textAlign:"end",justifyContent:"space-between",}}
                    button
                    key={settings.label}
                    component={Link}
                    to={settings.route}
                >
                    <ListItemIcon >{settings.icon}</ListItemIcon>
                    <ListItemText primary={settings.label} />
                </ListItem>
            ))}
        </List>
    </div>
  );  

  export const logo="./Images/Logo/logo.png"