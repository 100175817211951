import React from 'react';
import { Container, Typography, Grid, IconButton } from '@mui/material';
import { useSpring, animated } from 'react-spring';

import {SocialMedia} from "../../Datas/SocialMedia";
import {defaultBack, iconSize} from "../../Styles/Styles"

const Footer = () => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });
  
  
  return (
    <div >
      <Container maxWidth="xl" >
        <animated.div 
          style={{ 
            ...fadeIn, ...defaultBack,
            padding:"30px",
            paddingTop: '90px',
            border:"none",
            borderTop:"1px solid #f15723"
             }}>
            <Grid container spacing={3} style={{justifyContent:"space-between"}}>
              <Grid item xs={12} md={6} >
                <Typography variant="h6" gutterBottom>
                  About WILMAR SPORTS
                </Typography>
                <Typography variant="body2" color={"#f15723"}>
                Design and redefine Costumes, gears, training and support to tap extreme 
                performance. And nurturing outstanding competency. through spill the vibe of sportsman s
                pirit across the globe at any cost.
                </Typography>
                </Grid>
                  <Grid item xs={12} sm={6} style={{textAlign:"center"}}>
                    <Typography variant="h6" gutterBottom>
                      Connect with us
                    </Typography>
                    {
                        SocialMedia.map((media)=>
                        (
                          <IconButton key={media.id}
                          color="inherit"
                          href={media.route}
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                          <img style={iconSize} src={media.icon} alt={media.name} />
                          </IconButton>
                        ))
                    }
                  </Grid>
              </Grid>
            <Typography variant="body2" align="center" style={{backgoundColor:'#1c1c1c', marginTop: '90px', }}>
              &copy; 2023 WILMAR SPORTS. All rights reserved.
            </Typography>
        </animated.div>
      </Container>
    </div>
  );
};

export default Footer;
