import React,{useState} from 'react'

import {Container } from '@mui/material'

import { Outlet } from 'react-router'
import { Link } from 'react-router-dom'

function Account({back}) {

    const [active,setActive] = useState('login')

    let im={
        backgroundImage:'linear-gradient( rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url("/Images/background/6.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    let loginStyle={

    };
    let signupStyle={

    };

    if(active==='login'&& active!=='signup')
    {

        loginStyle=
        {
            backgroundColor:"#FF7E5F",
            color:"#ffff", 
            width:"130px",
            padding:"12px",   
            textAlign:"center",  
            borderRadius:"25px"
        }

        signupStyle=
        {
            backgroundColor:"#ffff",
            color:"#f15723", 
            width:"130px",
            padding:"12px",   
            textAlign:"center",  
            borderRadius:"25px"
        }
    }else  if(active==='signup'&& active!=='login')
    {
        loginStyle=
        {
            backgroundColor:"#ffff",
            color:"#f15723", 
            width:"130px",
            padding:"12px",   
            textAlign:"center",  
            borderRadius:"25px"
        }

        signupStyle=
        {
            backgroundColor:"#FF7E5F",
            color:"#ffff", 
            width:"130px",
            padding:"12px",   
            textAlign:"center",  
            borderRadius:"25px"
        }
    }

  return (
    <div style={{padding:"50px",...im}}>
     <Container maxWidth={'xs'} style={{display:"flex", justifyContent:"space-between",}}>
        <Link to={'signup'} style={{textDecoration:"none"}} >
             <div style={{...signupStyle}} onClick={()=>(setActive('signup'))}>
                 <h3>Sign up</h3>
             </div>
        </Link>
     
        <Link to={'login'} style={{textDecoration:"none",}} onClick={()=>(setActive('login'))}>
             <div style={{...loginStyle}}>
                <h3> Login</h3>
             </div>
        </Link>
   </Container>
      <Outlet/>
    </div>
  )
}

export default Account
