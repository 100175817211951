import React from 'react'
import './App.css';

import Navbar from './Components/Navbar/Navbar';
import Footer from "./Components/Footer/Footer";
import Banner from './Components/Banner/Banner'
import {AppContext} from './constants/AppContext'

import { BrowserRouter as Router,} from 'react-router-dom';

const wilmar = "./Images/Logo/logo.png"

function App() {
  
  return (
    <div className='App'>
      <AppContext.Provider value={{logo:wilmar}} >
        <Router>
          <Navbar/>
          <Banner/>
          <Footer/>
        </Router>
      </AppContext.Provider>
    </div>
  )
}

export default App;
