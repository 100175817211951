// Map.js
import React from 'react';

import { Container, Grid,  } from '@mui/material';


const containerStyle = {
  width: '100%',
  maxHeight: '500px',
  
};

const map1='./Images/map/map1.gif'
//const map2='./Images/map/map3.jpg'
const map3='/Images/map/map4.png'

function Map() {
  return (
    <div style={{paddingTop:"50px"}}>
      <Container maxWidth={'xl'}>
        <h2>To visit our company's physical address click the below Image/gif/video</h2>
         
            <Grid  container spacing={3} style={{padding:"50px",alignItems:"center",display:"flex"}} >
              <Grid item xs={12}>
                      <a href="https://maps.app.goo.gl/2nRRbLvme3BH8rgk6" rel="noreferrer noopener" target='_blank'>
                        <img style={{...containerStyle}} src={map1} alt='WILMAR SPORTS'/>
                      </a>
              </Grid>
              <Grid item xs={12}>
                      <a href="https://maps.app.goo.gl/2nRRbLvme3BH8rgk6" rel="noreferrer noopener" target='_blank'>
                        <img style={{...containerStyle}} src={map3} alt='WILMAR SPORTS'/>
                      </a>
              </Grid>
            </Grid>
          
      </Container>
    </div>
  );
}

export default Map;
