import React, { useState, useEffect } from 'react';
import { Link, Route, Routes } from 'react-router-dom';

import "./Navbar.css";

import {AppBar,Toolbar,IconButton,Typography,Drawer,} from '@mui/material';
import { Avatar, Button, Tooltip, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import { mainNavbarItems, userDrawerLinks,drawerLinks } from './consts/NavbarItems';
import {category,logoStyle} from '../../Styles/Styles';
import {logo} from './consts/NavbarItems'

import HomePage from '../Home/HomePage';
import AboutUs from '../About us/AboutUs';
import ServicePage from '../Services/ServicePage';
import ContactUs from '../Contact/ContactUs';
import CareerPage from '../Careers/Careers';
import Category from '../Category/Category';
import Signup from '../../User/Account/Signup/Signup';
import Products from '../Products/Products';
import Login from '../../User/Account/Login/Login';
import Display from '../display/Display';
import JobApplicationForm from '../Jobs/JobApplicationForm';
import Upspace from '../Spaces/Upspace'
import AdminHome from '../../Admin/AdminHome/AdminHome';
import Cart from '../../User/Cart/Cart';
import Profile from '../../User/Profile/Profile';
import Logout from '../../User/Account/Login/Logout';
import axios from '../../axios';

function Navbar() {
  const [user,setUser] = useState()
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);

  const navback={
    background:"linear-gradient(#000000,#000000), rgba(0,0,0,0.2)"
  }

  let userId=sessionStorage.getItem('user');

  useEffect(()=>{
    if(userId!=null)
    {
      try
    {
        axios.get(`user/get/id/${userId}`).then((response)=>{
          console.log(response)
          if(response.status===200)
          {
            setUser(response.data)
           
          }
        })
    }
    catch(error)
    {
      console.log(error);
    }
    }else
    {
        setUser(null)
    }
},[userId])

  return (
    <div>
      
      <AppBar  style={{...navback,justifyContent:"space-between"}}>
        
        <Container maxWidth="xl">
          <Toolbar disableGutters>

            {/* Toggle Bar Designs */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setDrawerOpen(true)}
                color="warning"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Link to="/">
              <Typography
                variant="h6"
                noWrap
                component="div"
                to="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', lg: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 800,
                  letterSpacing: '1rem',               
                  textDecoration: 'none',
                }}
              >
                <img
                  style={{
                    ...logoStyle
                  }}
                  src={logo}
                  alt="WILMAR"
                />
              </Typography>
            </Link>

            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', lg: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img
                style={{
                 ...logoStyle,
                }}
                src={logo}
                alt="WILMAR"
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
              {mainNavbarItems.map((item) => (
                <Button
                  className='nav-link'
                  key={item.id}
                  component={Link}
                  to={item.route}
                  sx={{ my: 3, color: '#f15723', display: 'block', mx: 3 }}
                >
                  {item.label}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={user!=null?user.userName:"login"}>
                <IconButton  onClick={() => setUserDrawerOpen(true)} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" sx={{ bgcolor: '#f15723' }} >{user!=null?user.userName.charAt(0  ):null}</Avatar>
                </IconButton>
              </Tooltip>
              <Drawer   anchor="right"
              size="md"
              open={userDrawerOpen} onClick={() => setUserDrawerOpen(false)}>
                 {userDrawerLinks}
              </Drawer>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
      <Drawer color="#f15723" anchor="top" open={drawerOpen} onClick={() => setDrawerOpen(false)}> 
        {drawerLinks}
      </Drawer>
      <Upspace/>    

      {/* Use React Router's Outlet to render child routes */}

      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="about" element={<AboutUs back={category} />} />
        <Route path="contact" element={<ContactUs back={category} />} />
        <Route path="career" element={<CareerPage back={category} user={user} />} />
        <Route path="career/application" element={<JobApplicationForm back={category} user={user}/>} />
        <Route path="display" element={<Display back={category} />} />
        <Route path="category" element={<Category back={category} />} ></Route>
        <Route path='product' element={< Products back={category} user={user}/>}/>
        <Route path="services" element={<ServicePage back={category} user={user} />}></Route>
        <Route path="product/cart" element={<Cart back={category} user={user}/>}></Route>
        <Route path="cart" element={<Cart back={category} user={user}/>}></Route>
        <Route path="profile" element={<Profile back={category}/>}>
          <Route path='signup' element={<Signup back={category}/>} />
          <Route  path='login' element={<Login back={category}/>} />
        </Route>
        <Route path="logout" element={<Logout back={category}/>}></Route>
        <Route path='admin' element={<AdminHome/>}/>
      </Routes>
    </div>
  );
}

export default Navbar;
