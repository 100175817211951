import React from 'react'

function ProductsData() {
  return (
    <div>
      
    </div>
  )
}

export default ProductsData

export const products =
{
    id:1,
    name:"",
    total:0,
    delivered:0,
    balance:0,
}