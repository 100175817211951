import React from 'react';
import { useTrail, animated, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

import { services } from "./ServiceData";

const ServicePage = ({ back }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    const trail = useTrail(services.length, {
        opacity: 1,
        transform: inView ? 'translateY(0px)' : 'translateY(-50px)',
        config: config.slow,
        from: { opacity: 0, transform: 'translateY(50px)' },
    });

    return (
        <div className='service' ref={ref} style={{ ...back, padding: "50px" }}>
            <Container maxWidth="lg" style={{ alignItems: "center" }}>
                <Typography variant="h4" align="center" gutterBottom pt={5} mb={5}>
                    Services
                </Typography>
                <Grid container spacing={3}>
                    {trail.map((style, index) => (
                        <Grid item xs={12} sm={6} md={4}  key={services[index].id}>
                            <animated.div style={{ ...style,  }}>
                                <Card style={{ padding: "10px", textAlign: "center", }}>
                                    <Link to={services[index].link} style={{ textDecoration: "none", color: "#000000" }}>
                                        <CardMedia component="img"
                                            src={services[index].imageUrl}
                                            alt={services[index].title}
                                            style={{maxWidth:"100px",top:0,left:0,right:0,bottom:0,margin:"auto",}}
                                        />
                                        <CardContent variant="h4">
                                            <strong>{services[index].title}</strong>
                                        </CardContent>
                                    </Link>
                                </Card>
                            </animated.div>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default ServicePage;
