
export const contact=
{
    name:"Phone",
    icon:"./icons/contact/phone.png",
    phone: 9020250007
}    


export const email=
{
    name:"Email",
    icon:"./icons/contact/email.png",
    email:"wilmarsports@gmail.com"
} 

export const location =
{
    name:"Map",
    icon :"./icons/contact/location.png",
    link:"https://maps.app.goo.gl/2nRRbLvme3BH8rgk6"
}