import React from 'react'

import { Box, Container, Typography, Grid, Card ,CardMedia, CardContent,Button } from '@mui/material'

import {cartProduct} from '../../Components/Products/Products'


function Cart({back}) {
  

    const calculateTotalPrice = () => {
        return cartProduct.reduce((total, product) => total + product.actualPrice, 0);
      };

  return (
    <div style={{...back}}>
      <Container>
      <Box mt={3}>
          <Typography variant="h4" component="h2">
            Your Cart
          </Typography>
          <Grid container spacing={3}>
            {cartProduct.map((cart) => (
                <Grid item key={cart.id} xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    alt={cart.name}
                    image={cart.images[0]}
                  />
                  <CardContent>
                    <Typography variant="h6">{cart.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Price: ₹{cart.actualPrice}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              ))}
          </Grid>
          <Box mt={2}>
            <Typography variant="h6" component="h3">
              Total Price: ₹{calculateTotalPrice()}
            </Typography>
            <Button variant="contained" color="warning">
              Buy Now
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  )
}

export default Cart
