import React, { useState } from 'react';
import {Container,TextField,Button,Select,MenuItem,FormControl,InputLabel,
        Checkbox,Box,Typography,Paper,Grid,} from '@mui/material';
        
import axios from '../../axios'; 
import GetData from '../../Components/Category/CategoryData';

const initialProduct = {
  name: '',
  sizes: [],
  colors: [],
  category: '',
  gender:'',
  actualPrice: 0,
  demoPrice: 0,
  stock: 0,
  status: true,
  description: '',
  features: '',
};


const EditProducts = () => {

  const [updatedProduct, updateProduct] = useState(initialProduct);
  const [product,setProduct] = useState([])
  const [images,setImages] = useState([])
  const [id,setId] = useState()

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    updateProduct((prevProduct) => ({
      ...prevProduct,
      [name] : type === 'checkbox' ? checked : value,
    }));
  };


  const getProduct=(e)=>
  {
    e.preventDefault();
    try
    {
        axios.get(`product/get/${id}`).then((response)=>{
            console.log(response.data)
            setProduct(response.data)
        })
    }catch(error)
    {
        alert('Cannot complete the request')
    }

  }

  const handleImage =(e)=>
  {
    const selectedImages = Array.from(e.target.files);
   setImages(selectedImages);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    // Append the product JSON data to the form data
    formData.append('product', new Blob([JSON.stringify(updatedProduct)], { type: 'application/json' }));
  
    // Append image files to the form data
    if (images.length > 0) {
      images.forEach((image, index) => {
        formData.append('imageFile', image);
      });
    }
  
    // API Request

    try {
      const response = await axios.post('/product/update', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
        
      });
  
      console.log(response.data);
  
      if (response.status === 201) {
        alert('Product added successfully');
      } else {
        alert('Something went wrong..');
      }
    } catch (error) {
      alert('Cannot send the request');
      console.log(error);
    }
  };
  
  const category = GetData();
  
  return (
    <Container style={{paddingTop:"50px",paddingBottom:"50px"}}>
      <Typography variant="h4" component="h1" align="center">
        Update Product
      </Typography>
      <Paper elevation={3} style={{ paddingTop: '50px',marginTop:"20px",padding:"25px" }}>
        <form onSubmit={getProduct}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <TextField
                        name="id" color="warning"
                        label="Enter Product ID"
                        fullWidth 
                        
                        onChange={(e)=>(setId(e.target.value))}
                    />
                    
                </Grid>
                <Grid item xs={6} md={6}>
                    <Box mt={2} align='center'>
                        <Button type="submit"  variant="contained" color="warning">
                            Search
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
      </Paper>
      <Paper elevation={3} style={{ paddingTop: '50px',marginTop:"20px",padding:"25px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel>Product ID</InputLabel>
              <TextField
                name="id" color="warning"
                fullWidth disabled
                value={product.productID}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Product Name</InputLabel>
              <TextField
                name="name" color="warning"
                fullWidth required type='text'
                value={product.name}
                onChange={handleChange}
              />
            </Grid>
           
            <Grid item md={4} xs={12}>
              <InputLabel>Actual Price</InputLabel>
              <TextField
                name="actualPrice" 
                fullWidth required
                type="number" color="warning"
                value={product.actualPrice}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputLabel>Demo Price</InputLabel>
              <TextField
                name="demoPrice" color="warning"
                fullWidth required
                type="number"
                value={product.demoPrice}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputLabel>Stock</InputLabel>
              <TextField
                name="stock"
                color="warning"
                fullWidth required
                type="number"
                value={product.stock}
                onChange={handleChange}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl fullWidth color="warning">
                <InputLabel>Category</InputLabel>
                <Select name="category"
                fullWidth required
                value={updatedProduct.category}
                onChange={handleChange}>
                    {
                        category.map((items)=>(
                            <MenuItem key={items.id} value={items.name}>{items.name}</MenuItem>
                        ))
                    }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth color="warning"> 
                <InputLabel>Colors</InputLabel>
                <Select
                  name="colors"
                  multiple color="warning" fullWidth
                  value={updatedProduct.colors}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {['Red', 'Blue', 'Green', 'Yellow'].map((color) => (
                    <MenuItem key={color} value={color}>
                      <Checkbox checked={updatedProduct.colors.includes(color)} />
                      {color}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth required color="warning">
                <InputLabel>Sizes</InputLabel>
                <Select
                  name="sizes"
                  multiple 
                  value={updatedProduct.sizes}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {['S', 'M', 'L', 'XL','XXL'].map((size) => (
                    <MenuItem key={size} value={size}>
                      <Checkbox checked={updatedProduct.sizes.includes(size)} />
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={4} xs={12} >
              <FormControl fullWidth required color="warning">
                <InputLabel>Status</InputLabel>
                <Select name="status" value={updatedProduct.status} onChange={handleChange}>
                  <MenuItem value="true">In Stock</MenuItem>
                  <MenuItem value="false">Out of Stock</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth required color="warning">
                <InputLabel>Sex</InputLabel>
                <Select name="gender" value={updatedProduct.gender} onChange={handleChange}>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="unisex">Unisex</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <InputLabel>Product Description</InputLabel>
              <TextField
                name="description"
                multiline color="warning"
                fullWidth required type='text'
                value={product.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <InputLabel>Product Features</InputLabel>
              <TextField
                name="features" type='text'
                multiline color="warning"
                fullWidth required
                value={product.features}
                onChange={handleChange}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Box mt={2}>
                <Typography>
                 
                </Typography>
              </Box>
            </Grid> */}
            <Grid item md={6} xs={12} mt={3}>
              <input
                type="file"
                name="images"
                id="images"
                multiple
                onChange={handleImage}
                style={{ display: 'none' }}
              />
              <label htmlFor="images">
                <Button component="span" variant="contained" color="warning">
                  Upload Images
                </Button>
              </label>
              <Box >
                {images.length > 0 && (
                  <Typography>
                    Selected Images: {images.map((image) => image.name).join(', ')}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box mt={4} align='center'>
            <Button type="submit"  variant="contained" color="warning">
              Add Product
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default EditProducts;
