// Login.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {  Container,  TextField,  Button,  CircularProgress,  Grid,} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import {theme} from '../../../Styles/Themes'
import axios from '../../../axios';


const Login = ({back}) => {
  
  const navigate = useNavigate()
  const [login, setLogin] = useState({
    mobileNumber:"",
    password:""
  });
  const [loading, setLoading] = useState(false);

  const handleChange=(event)=>
  {
    const {name,value}=event.target;
    setLogin({
      ...login,[name]:value,
    })
  }

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
  
            //API Request

    try {
        axios.post("user/login",login)
        .then((response)=>{
          console.log(response)
          if (response.status === 200) 
          {
            console.log(response.data);
            sessionStorage.setItem("user",response.data.id)
            alert(`Login Successfully..  welcome ${response.data.userName}..`);
            window.location.reload();
            navigate("../product")
          } else {
            alert("No Account found for this Id..");
          }
        }).catch((error)=>{
          alert("Cannot complete the request..")
          console.log(error)
        })
      
    } catch (error) {
      console.error(error);
      alert("Something went wrong, please try again later");
    }
  
    setLoading(false);
  };
  

  return (
    <ThemeProvider theme={theme}>
      <div style={{...back,paddingTop:'65px',paddingBottom:'25px'}}>
        <Container maxWidth="md">
  
          <form onSubmit={handleLogin}>
            <Grid container >
                    <Grid item xs={12}>
                      <TextField
                          label="Mobile Number" name='mobileNumber'
                          id='mobile'
                          fullWidth required
                          onChange={handleChange}
                          margin="normal" type='number'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                          label="Password"  required
                          name='password'
                          id='upass'
                          fullWidth
                          margin="normal"
                          type="password"
                          onChange={handleChange}
                      />
                    </Grid>

                    <div style={{textAlign:"right",paddingBottom:"9px"}}>   
                    forget password ? 
                    <Link to={'../signup'} style={{textAlign:"right",padding:"5px",textDecoration:"none"}}>
                      Click Here
                    </Link>
                    </div>

                    <Button
                        variant="contained" type='submit'
                        color="warning"
                        fullWidth
                        
                        disabled={loading}
                        sx={{
                          mt:2,
                          mb:3,
                        }}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Login'}
                    </Button>
              </Grid>
            </form>

        </Container>
      </div>
    </ThemeProvider>
  );
};

export default Login;
