import React, { useState, useEffect } from 'react';
import { Card, CardMedia, Grid } from '@mui/material';

import {CarouselSize} from '../../../Styles/Styles'
import {gifData} from './Gifs'


const GifCarousel = ({ height, timerInterval }) => {
  const [currentGif, setCurrentGif] = useState(0);

  useEffect(() => {
    let intervalId;

    if (timerInterval > 0) {
      intervalId = setInterval(goToNextGif, timerInterval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentGif, timerInterval]);

  const goToNextGif = () => {
    setCurrentGif((prevGif) => (prevGif === gifData.length - 1 ? 0 : prevGif + 1));
  };

  return (
    <div style={{ height: height || 'auto' }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Card>
              <CardMedia component="img" height={height || 'auto'} style={CarouselSize} image={gifData[currentGif].src} alt="WILMAR SPORTS"/>
            </Card>
          </Grid>
        </Grid>
    </div>
  );
};

export default GifCarousel;
