import React from 'react'

import "./HomePage.css"

import GifCarousel from '../Carousel/Gifs/GifCarousel';
import QuoteCarousel from '../Carousel/Quotes/QuoteCarousel';
import ContactUs from '../Contact/ContactUs';
import ServicePage from '../Services/ServicePage';
import AboutUs from '../About us/AboutUs';
import Banner from '../Banner/Banner';
import Category from '../Category/Category';
import Products from '../Products/Products';


function HomePage() {

  const backImage = {
    // backgroundImage:'linear-gradient( rgba(255, 49, 3, 0.896), rgba(251, 255, 0, 0.75)),url("/Images/background/1.jpg")',
    // background:"linear-gradient(to bottom, Transparente 0%,Transparente 50%,red 50%,red 100%)",
    // backgroundSize: 'contained',
    // backgroundPosition: 'center',
    // minHeight: '100vh',
    backgroundColor:"#000000"
  };
  
  const backColor=
  {
    backgroundColor:"transparent",
    color:"#f15723",
  }

  return (
    <div className='home' style={{...backImage}}>
        <GifCarousel  timerInterval={5000} />
        <Banner/>
        <QuoteCarousel intervalInSeconds={5} back={backColor} />
        <ServicePage back={backColor}/>
        <Category back={backColor}/>
        <ContactUs back={backColor}/>
        <Products back={backColor}/>
        <AboutUs back={backColor}/>
    </div>
  )
}

export default HomePage;
