import React,{useState} from 'react'

import { Button, Container, Grid, InputLabel, Paper, TextField, Typography } from '@mui/material'

function CandidateRegistrationForm() {

    const [candidate,setCandidate] = useState({
        fullName:"",
        nationality:"",
        idNumber:"",
        sports:"",
        dateOfBirth:"",
        height:0,
        age:0,
        weight:0,
        blood:"",
        activity:"",
        requirements:""
    });

    const handleChange=(e)=>
    {
        const {name,value} = e.target;

        setCandidate((prevCandidate)=>({
            ...prevCandidate,
            [name]:value
        }))
    }

    const handleSubmit=(e)=>
    {
        e.preventDefault();
        console.log(candidate)
    }

  return (
    <div>
        <Container maxWidth='xl'>
            <Paper elevation={3} style={{background:"#ffff",padding:"10px",paddingBottom:"40px",paddingTop:"40px",display:"flex"}}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align='center' variant='h4'>
                                Candidate Registration Form
                            </Typography>
                        </Grid>  
                        <Grid item xs={12} md={6}> 
                            <TextField  onChange={handleChange}
                                fullWidth required color='warning'
                                name='fullName' type='text'
                                id='fullname' label="Full Name"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField onChange={handleChange}
                                fullWidth required color='warning'
                                name='nationality' type='text'
                                id='nationality' label="Nationality"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <InputLabel>Identification</InputLabel>
                            <TextField onChange={handleChange}
                                fullWidth required color='warning'
                                name='idNumber' type='text'
                                id='idNumber' 
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                        <InputLabel>Identification photo</InputLabel>
                            <TextField color='warning'
                                fullWidth required
                                name='idCard' type='file'
                                id='idCard' 
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Date of Birth</InputLabel>
                            <TextField 
                                fullWidth required color='warning'
                                name='dateOfBirth' type='date'
                                id='dateOfBirth' onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <InputLabel>Sports Name</InputLabel>
                            <TextField onChange={handleChange}
                                fullWidth required color='warning'
                                name='sports' 
                                id='sports' type='text'
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField onChange={handleChange}
                                fullWidth required placeholder='ex : 150 (in centemetre)'
                                name='height' type='number' color='warning'
                                id='height' label="Height"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField onChange={handleChange}
                                fullWidth required placeholder='ex : 50 (in Kilogram)'
                                name='weight' type='number' color='warning'
                                id='weight' label="Weight"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField onChange={handleChange}
                                fullWidth required placeholder='ex : 150 (in centemetre)'
                                name='blood' type='text' color='warning'
                                id='blood' label="Blood Group"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField  color='warning'
                                fullWidth required onChange={handleChange}
                                name='age' type='number'
                                id='age' label="age"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                        <InputLabel>Income proof of parents</InputLabel>
                            <TextField  color='warning'
                                fullWidth required
                                name='income' type='file'
                                id='income'
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                        <InputLabel>Self Introduction Video</InputLabel>
                            <TextField  color='warning'
                                fullWidth required
                                name='selfVideo' type='file'
                                id='selfVideo'
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                        <InputLabel>Sports Activity</InputLabel>
                            <TextField color='warning'
                                fullWidth required
                                name='activity' type='text'
                                id='activity' onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                        <InputLabel>Sports Certificate</InputLabel>
                            <TextField color='warning'
                                fullWidth required
                                name='certificate' type='file'
                                id='certificate' 
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField color='warning' onChange={handleChange}
                                fullWidth required multiline
                                name='requirements' type='text'
                                id='requirements' label='Sports Requirements'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth color='warning' variant='contained' type='submit'>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    </div>
  )
}

export default CandidateRegistrationForm
