export const bannerImages=
[
    '/Images/banner/1.png',
    '/Images/banner/2.png',
    '/Images/banner/3.png',
    '/Images/banner/4.png',
    '/Images/banner/5.png',
    '/Images/banner/6.png',
    '/Images/banner/7.png',
    '/Images/banner/8.png',
    '/Images/banner/9.png',
    '/Images/banner/10.png',
    '/Images/banner/1.png',
    '/Images/banner/2.png',
    '/Images/banner/3.png',
    '/Images/banner/4.png',
    '/Images/banner/5.png',
    '/Images/banner/6.png',
    '/Images/banner/7.png',
    '/Images/banner/8.png',
    '/Images/banner/9.png',
    '/Images/banner/10.png',
    
]
