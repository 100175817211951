export const logoStyle=
{
    maxWidth : "250px"
}
export const defaultBack =
{
    backgroundColor: "#000000",
    color:"#ffff",
}

export const boxImage =
{
    maxWidth :"230px",
    maxHeight : "250px",
}
export const bannerImage =
{
    maxWidth :"80px",
    maxHeight : "50px"
}

export const wilmarBack=
{
    backgroundColor:"#f15723"
}

export const CarouselSize=
{
    maxWidth : "100%",
    maxHeight : "600px"
}


export const iconSize =
{
    maxWidth:"30px",
    maxHeight:"30px",
}

// Inside your component

export const linkStyle = {
    textDecoration: 'none',
    color: '#f15723', // Use the default text color
    transition: 'color 0.3s', // Add transition for smooth color change
  };
  
export  const activeLinkStyle = {
    backgroundColor: '#f15723',
    color:"#ffff" 
  };
  
export const aboutus=
{
    backgroundColor:"#F1A223 ",
    color:"#000000"
}

export const category=
{
    backgroundImage: 'linear-gradient(#FF7E5F, #FEB47B )',
    color:"#000000"
}

export const service=
{
    backgroundColor:"#F15723 ",
    color:"#000000"
}