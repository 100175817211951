import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTrail, animated, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';

import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

import { boxImage } from '../../Styles/Styles';
//import {category} from './CategoryData';
import axios from '../../axios'

const Category = ({ back }) => {

    const [category,setCategory] = useState([]);
    const [isHovering,setHovering] = useState(false)

    useEffect(()=>{
        
        try{
            axios.get("product/category/get").then((response)=>{
                console.log(response)
                
                if(response.status===200)
                {
                    setCategory(response.data)

                }else
                {
                    console.log("cannot retrieve category")
                }

            })
        }catch(error)
        {
            alert("Cannot load category")
            console.log(error)
        }

    },[])    

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    const trail = useTrail(category.length, {
        opacity: 1,
        transform: inView ? 'translateY(0px)' : 'translateY(-50px)',
        config: config.slow,
        from: { opacity: 0, transform: 'translateY(50px)' },
    });

    return (
        <div className='service' ref={ref} style={{ ...back, padding: "50px" }}>
            <Container maxWidth="lg" style={{ alignItems: "center" }}>
                <Typography variant="h4" align="center" gutterBottom pt={5} mb={5}>
                    Category
                </Typography>
                <Grid container spacing={3}>
                    {trail.map((style, index) => (
                        <Grid item xs={12} sm={6} md={4}  key={category[index].id} onMouseEnter={()=>(<p>{category[index].name}</p>)} >
                            <animated.div style={{ ...style,  }}>
                                <Card style={{ padding: "10px", textAlign: "center", }}>
                                    <Link to={category[index].name} style={{ textDecoration: "none", color: "#000000" }}>
                                        <CardMedia component="img" height="200"
                                            src={`data:image/jpeg;base64,${category[index].image}`}
                                            alt={category[index].name}
                                            style={{...boxImage,top:0,left:0,right:0,bottom:0,margin:"auto",}}
                                        />
                                        <CardContent variant="h4">
                                            <strong>{category[index].name}</strong>
                                        </CardContent>
                                    </Link>
                                </Card>
                            </animated.div>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default Category;
