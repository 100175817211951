
import { useNavigate } from 'react-router';

import axios from '../../../axios';

function Logout() {

  const navigate=useNavigate()
  
  let id= sessionStorage.getItem("user")

  if(id!=null)
  {
    
      try
    {
      axios.get("user/logout").then((response)=>{
        console.log(response)
        if(response.status===202)
        {
          sessionStorage.removeItem('user');
            alert("Logout successfully");
           window.location.reload();
        }
      },[])
    }
    catch(error)
    {
      console.log(error)
      alert("cannot complete the request..")
    }
  }else
  {
    navigate("../profile/login")
  }
  
}

export default Logout
