import React from 'react'
import Space from './Space'

function Upspace() {
  return (
    <div style={{padding:"26px",
                maxHeight:"100px",background:"transparent",
                width:"100%",
                }}>
      <Space/>
    </div>
  )
}

export default Upspace
