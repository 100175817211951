import React,{useEffect,useState} from 'react'

import { Container, Grid, Paper, Typography } from '@mui/material'

import {products} from '../Products/ProductsData';
import Chart from './Chart/Chart';
import axios from '../../axios'

function Dashboard() {

    const [count,setCount] = useState()

    useEffect(()=>{

        // Api Request 

        try
        {
            axios.get("product/get/count").then((response)=>
            {
                console.log(response)
                
                if(response.status===200)
                {
                    setCount(response.data)
                }else
                {
                    setCount('error..')
                }
            })
        }catch(error)
        {
            console.log(error)
        }

    },[])

    const sizeStyle=
    {
        maxHeight:"200px",
        width:"100%"
    }
  return (
    <div >
        <Container maxWidth={'lg'}>
            <Typography variant='h4' style={{textAlign:"center"}}>
                Dash Board
            </Typography>
            <Grid container spacing={1} style={{justifyContent:"space-between",textAlign:"center",paddingTop:'30px'}}>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper style={{...sizeStyle}}>
                        <Typography variant='h5'>
                            Total Products
                        </Typography>
                        <Typography variant='h6'>
                            {count}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper style={{...sizeStyle}}>
                        <Typography variant='h5'>
                            Delivered Products
                        </Typography>
                        <Typography variant='h6'>
                            {products.delivered}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper style={{...sizeStyle}}>
                        <Typography variant='h5'>
                            Remaining Products
                        </Typography>
                        <Typography variant='h6'>
                            {products.balance}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Chart/>
        </Container>
    </div>
  )
}

export default Dashboard;