export const jobs= 
[
    {
        id: 1,
        type: 'Job Application',
        title: 'Graphic Designer',
        description: 'We are looking for a talented Graphic Designer to join our team. freshers are also welcome.',
        experience:"0-3 years",
        location: 'Neyyatinkara, Trivandrum Kerala',
        date: 'Posted on: October 15, 2023',
    },
    {
        id: 2,
        type: 'Job Application',
        title: 'Telecaller',
        description: 'We are looking for a talented Telecaller to join our team. freshers are also welcome. ',
        experience:"0-3 years",
        location: 'Neyyatinkara, Trivandrum Kerala',
        date: 'Posted on: October 15, 2023',
    },
    {
        id: 3,
        type: 'Job Application',
        title: 'Human Resources Manager',
        description: 'We are looking for a talented software developer to join our team. freshers are also welcome.',
        experience:"0-3 years",
        location: 'Neyyatinkara, Trivandrum Kerala',
        date: 'Posted on: October 15, 2023',
    },
    {
        id: 4,
        type: 'Job Application',
        title: 'Bussiness Analyst',
        description: 'We are looking for a talented Bussiness Analyst to join our team. freshers are also welcome.',
        experience:"0-3 years",
        location: 'Neyyatinkara, Trivandrum Kerala',
        date: 'Posted on: October 15, 2023',
    },
    {
        id: 5,
        type: 'Job Application',
        title: 'Production Associate',
        description: 'We are looking for a talented Production Associate to join our team. freshers are also welcome.',
        experience:"0-3 years",
        location: 'Neyyatinkara, Trivandrum Kerala',
        date: 'Posted on: October 15, 2023',
    },
    {
        id: 6,
        type: 'Job Application',
        title: 'Tailor',
        description: 'We are looking for a talented Tailor to join our team.',
        experience:"1-3 years",
        location: 'Neyyatinkara, Trivandrum Kerala',
        date: 'Posted on: October 15, 2023',
    },
]

export const events =
[
    {
        id: 1,
        type: 'Event',
        title: 'Tech Conference 2023',
        description: 'Join us for the biggest tech conference of the year.',
        location: 'San Francisco, CA',
        date: 'Date: November 10-12, 2023',
    },
    {
        id: 2,
        type: 'Event',
        title: 'Tech Conference 2023',
        description: 'Join us for the biggest tech conference of the year.',
        location: 'San Francisco, CA',
        date: 'Date: November 10-12, 2023',
    },
    {
        id: 3,
        type: 'Event',
        title: 'Tech Conference 2023',
        description: 'Join us for the biggest tech conference of the year.',
        location: 'San Francisco, CA',
        date: 'Date: November 10-12, 2023',
    }
]