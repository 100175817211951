import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import { useSpring, animated } from 'react-spring';

const AnimatedDialog = ({ open, onClose }) => {
  const [show, setShow] = useState(open);

  const animationProps = useSpring({
    opacity: show ? 1 : 0,
    transform: show ? 'scale(1)' : 'scale(0.5)',
    config: { tension: 300, friction: 20 },
  });

  // Update 'show' state when the dialog opens or closes
  React.useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <animated.div style={animationProps}>
        <DialogTitle>Animated Dialog</DialogTitle>
        <DialogContent>
          <Typography>This is an animated pop-up dialog!</Typography>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </DialogContent>
      </animated.div>
    </Dialog>
  );
};

export default AnimatedDialog;
