import React,{useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Button, Container, Grid, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {theme} from '../../../Styles/Themes';
import axios from '../../../axios';


function Signup({back}) {

  const navigate = useNavigate();
  const [saveUser,setSaveUser] = useState({
    userName:"",
    country:"",
    mobileNumber:"",
    email:"",
    gender:"",
    password:"",
  })
  const [user,setUser]=useState()

  const handleChange=(e)=>
  {
      const {name,value}=e.target;
      setSaveUser({
        ...saveUser,
        [name]:value,
      });
  }

  const handleSubmit=(e)=>
  {
    e.preventDefault();
    console.log(saveUser)

    // API request

    try
    {
      axios.post("user/signup",saveUser).then((response)=>{
            console.log(response)
            if(response.status ===200)
            {
              setUser(response.data)
              alert(`Welcome ${response.data.userName}`)
              sessionStorage.setItem('user', response.data.id);
              console.log(user)
              navigate("/profile")
            }else{
              alert('something went wrong')
            }
      })

    }catch(error)
    {
          alert('Cannot send the request')
    }
    
  }

  return (
    <ThemeProvider theme={theme}>
        <div style={{...back,paddingTop:'35px'}}>
          <Container>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} style={{textAlign:"center",
              fontFamily: 'Cinzel, sans-serif',padding:"20px",}}>
                <Grid item xs={12} md={6}>
                  <TextField id="standard-basic-name" 
                    name='userName' onChange={handleChange}
                     required label='Name' fullWidth 
                     variant="standard" />
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <TextField id="standard-basic-date" 
                  name='country' onChange={handleChange} 
                  required label='Country' fullWidth 
                  variant="standard" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField id="standard-basic-number" 
                  name='mobileNumber' onChange={handleChange} 
                  type='number' required  label='MobileNumber' 
                  fullWidth variant="standard" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField id="standard-basic-email" 
                  name='email' onChange={handleChange} 
                  type='email' label='Email' fullWidth 
                  variant="standard" />
                </Grid>
                <Grid item xs={12} md={6}>
                <FormControl required >
                  <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                  <RadioGroup 
                    row 
                    name="gender" onChange={handleChange}
                  >
                    <FormControlLabel   value="female" control={<Radio />} label="Female" />
                    <FormControlLabel   value="male" control={<Radio />} label="Male" />
                    <FormControlLabel   value="other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField id="standard-basic-password" 
                name='password' onChange={handleChange} 
                type='password' required  label='Create Password' 
                fullWidth variant="standard" />
                </Grid>
                <Grid item xs={12} >
                  <Button color='warning' type='submit'
                      variant='contained'
                      fullWidth
                      sx={{
                        mt:3,
                        mb:3,
                      }}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </form>
        </Container>
      </div>
    </ThemeProvider>
  )
}

export default Signup;
