import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import './QuoteCarousel.css'; 

import {quotes} from "./Quotes"

const QuoteCarousel = ({ intervalInSeconds,back }) => {
  
  const [currentQuote, setCurrentQuote] = useState(0);
  const [slideDirection, setSlideDirection] = useState('right');

  useEffect(() => {
    let intervalId;

    if (intervalInSeconds > 0) {
      intervalId = setInterval(goToNextQuote, intervalInSeconds * 1000); // Convert seconds to milliseconds
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentQuote, intervalInSeconds]);

  const goToNextQuote = () => {
    setSlideDirection('left');
    setTimeout(() => {
      setCurrentQuote((prevQuote) => (prevQuote === quotes.length - 1 ? 0 : prevQuote + 1));
      setSlideDirection('right');
    }, 300); // Adjust the duration as needed (in milliseconds)
  };

  const slideStyles = {
    animation: `slide-${slideDirection} 0.3s`,
    ...back,
    outline: "none",
    border:"none",
  };

  return (
    <div className="quote-carousel-container" style={{...back,paddingTop: '50px'}}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Card className="quote-card" style={slideStyles}>
            <CardContent>
              <Typography variant="h5" gutterBottom color="#ffff">
                {quotes[currentQuote].quote}
              </Typography>
              <Typography variant="h6" color="#f15723"
                sx={{
                  fontWeight:"900"
                }}
              >
                - {quotes[currentQuote].author}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuoteCarousel;
