import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

import axios from '../../axios'

function AddCategory() {

    const [category,setCategory] = useState({
      name:"",
      quote:"",
    })
    const [categoryImage,setCategoryImage] = useState([])
    const [preview,setPreview] = useState([])

    const handleImage=(e)=>{
      setCategoryImage(e.target.files[0])
      const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    }

    const handleChange=(e)=>
    {
      const {name,value}=e.target;
      setCategory((prevCategory)=>({
        ...prevCategory,
        [name] : value
      }));
    }

    const handleSubmit= async (e)=>
    {
        e.preventDefault()
        console.log(category)

        const formData = new FormData(); 
        formData.append('category',new Blob([JSON.stringify(category)], {type : "application/json" }));
        if(categoryImage)
        {
          formData.append('image',categoryImage)
        }
        console.log(formData)
        try
        {
          const response = await axios.post("product/category/add", formData, {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
            
          }).catch((error)=>{
            console.log(error)
          });
            
              console.log(response)

              if(response.status===200)
              {
                alert("Category added successfully")
                e.target.reset();
                setPreview([])
              }else
              {
                alert("Request Failed")
              }
           
        }
        catch(error)
        {
          alert("cannot send the request")
          console.log(error)
        }
    }

  return (
    <div style={{paddingBottom:"50px",paddingTop:"50px"}}>
      <Container maxWidth='lg'>
        <Typography variant='h4' align='center'>Add Category</Typography>
          <Paper elevation={3} style={{ paddingTop: '50px',marginTop:"20px",padding:"25px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{paddingTop:"30px"}}>
            <Grid item xs={12} md={6}>
                <TextField 
                    fullWidth required
                    name='name' color='warning'
                    label="Category Name"
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField 
                    fullWidth required
                    name='quote' multiline
                    label="Quote" color='warning'
                    onChange={handleChange}
                />
            </Grid>
            <Grid item md={12} xs={12}>
              <input
                type="file"
                name="image"
                id="image"
                multiple
                onChange={handleImage}
                style={{ display: 'none' }}
              />

              <label htmlFor="image">
                <Button component="span" variant="contained" color="warning">
                  Upload Images
                </Button>
              </label>
              
              {
                preview.length > 0 &&
                (
                  <Grid>
                    <Box mt={2}>
                      <Typography>
                        Selected Images: {preview.name}
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <img key={preview.lastModified} style={{width:"100px",height:"100px"}}
                        src={preview} alt={preview.name} />
                    </Box>
                  </Grid>
                )
              }
            </Grid>
            <Grid item xs={12}>
                <Button type="submit" color='warning' variant='contained' fullWidth>
                    Save
                </Button>
            </Grid>
          </Grid>
        </form>
          </Paper>
      </Container>
    </div>
  )
}

export default AddCategory
