import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';

import {jobs,events} from "../../Datas/Careerdata";
import { Link } from 'react-router-dom';


const CareerPage = ({back}) => {
  return (
    <div style={{...back,}}>
      
      <Container maxWidth="lg" style={{
           paddingBottom:"50px"
      }}>
          <div style={{height:"90px"}}>

          </div>
        <Typography variant="h4" align="center" gutterBottom style={{textAlign:"center",paddingBottom:"20px"}}> 
          Career Opportunities
        </Typography>
          
        <Grid container spacing={5}>
          {jobs.map((item) => (
            <Grid item xs={12} sm={6} key={item.id}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {item.type}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {item.description}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                  <b>Experience : </b> {item.experience}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Location:</strong> {item.location} <br /> <br />
                  </Typography>
                  <Typography variant="body2">
                    {item.date}<br /> <br />
                  </Typography>
                  {item.type === 'Job Application' && (
                    <Link to={'application'}>
                      <Button variant="contained" color="warning">
                        Apply Now
                      </Button>
                    </Link>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
                      <div style={{height:"90px"}}>

                      </div>
        <Typography variant='h3' align='center'>
          Events
        </Typography>
                      <div style={{height:"60px"}}>

                      </div>
        
        <Grid container spacing={5}>
          {events.map((item) => (
            <Grid item xs={12} sm={6} key={item.id}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {item.type}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {item.description}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Location:</strong> {item.location}
                  </Typography>
                  <Typography variant="body2">
                    <strong>{item.date}</strong>
                  </Typography>
                  {item.type === 'Job Application' && (
                    <Button variant="contained" color="warning">
                      Apply Now
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

      </Container>
    </div>
  );
};

export default CareerPage;
