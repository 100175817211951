import  { useEffect ,useState} from 'react';

import axios from '../../axios'

export const category = [
    {
        id:1,
        img: './Images/category/football.jpg',
        name: 'Football',
        route:'football',
    },
    {
        id:2,
        img: './Images/category/volleyball.jpg',
        name: 'Volleyball',
        route:'volleyball',
    },
    {
        id:3,
        img: './Images/category/cricket.jpg',
        name: 'Cricket',
        route:'cricket',
    },
    {
        id:4,
        img: './Images/category/gym.jpg',
        name: 'Gym',
        route:'gym',
    },
    {
        id:5,
        img: './Images/category/jersey.jpg',
        name: 'Jersey',
        route:'jersey',
    },
    {
        id:6,
        img: './Images/category/men.jpg',
        name: 'Men',
        route:'men',
    },
    {
        id:7,
        img: './Images/category/women.jpg',
        name: 'Women',
        route:'women',
    },
    {
        id:8,
        img: './Images/category/gym2.jpg',
        name: 'Gym',
        route:'gym',
    },
    {
        id:9,
        img: './Images/category/boxing.jpg',
        name: 'Boxing',
        route:'boxing',
    },
    
  ];

  const GetData=()=>
  {
    const [category,setCategory] = useState([]);

    useEffect(()=>{
        
        try{
            axios.get("product/category/get").then((response)=>{
                console.log(response)
                
                if(response.status===200)
                {
                    setCategory(response.data)

                }else
                {
                    console.log("cannot retrieve category")
                }

            })
        }catch(error)
        {
            alert("Cannot load category")
            console.log(error)
        }

    },[])    

    return category;
  }
  
  export default GetData;