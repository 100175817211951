import React from 'react';
import axios from '../../axios'
import { Button } from '@mui/material';
import Products from '../Products/Products';
import CandidateRegistrationForm from '../../User/services/sponsorship/CandidateRegistrationForm';


const Display = ({back}) =>
{

    const dis=()=>
    {
        console.log('hey')
        axios.get('user/get/all').then((res)=>{
            console.log(res.data)
            const data=res.data
            console.log(data)
            return(
                <div>
                    <h1 style={{color:"#ffff"}}>{data}</h1>
                </div>
            )
        })
    }

    return(
        <div style={{width:"100%"}}>
           <div style={{...back,padding:"50px"}}>
                <Button onClick={dis} style={{
                    top:0,
                    left:0,
                    right:0,
                    bottom:0,
                    margin:'auto'
                }}>
                    show
                </Button>
                <Products/>
                <CandidateRegistrationForm/>
           </div>
        </div>
    )
}

export default Display;